<template>
	<v-container fluid fill-height class="lightgrey">
		<v-container>
			<v-row class="text-center">
				<v-col cols="12">
					<v-icon x-large color="red">mdi-alert-circle</v-icon>
					<h1 class="headline font-weight-bold my-3" v-html="errorTitle" />
					<p class="subheading font-weight-regular mb-5" v-html="errorText" />
					<div class="px-6">
						<AButton
							:title="errorButtonText"
							color="secondary"
							:block="true"
							:outlined="true"
							@click="errorFunction()"
						/>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import AButton from '@/components/atoms/AButton'

export default {
	components: {
		AButton
	},
	computed: {
		errorTitle() {
			const errorCode = this.$route.params.errorCode
			let title = this.$t(`common.error.${errorCode}.title`)
			if (!title) title = this.$t(`common.error.all.title`)
			return title
		},
		errorText() {
			const errorCode = this.$route.params.errorCode
			let text = this.$t(`common.error.${errorCode}.text`)
			if (!text) text = this.$t(`common.error.all.text`)
			return text
		},
		errorButtonText() {
			const errorCode = this.$route.params.errorCode
			let text = this.$t(`common.error.${errorCode}.buttonText`)
			if (!text) text = this.$t(`common.error.all.text`)
			return text
		}
	},
	methods: {
		errorFunction() {
			if (this.$route.params.errorCode === '500') this.$router.push('/')
			else this.$router.push('/')
		}
	}
}
</script>
